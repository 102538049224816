* {
  /* make everything unselectable by default */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.judeImage {
  width: 20%;
  border: solid 1px blueviolet;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  margin: 0
}

.App-header {
  background: linear-gradient(#90003c, #2b0041);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #a200ff;
  font-size: calc(10px + 1vmin);
}

.App-link-text {
  color: #aaaaaa;
  font-size: calc(10px + 0.5vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li {
  text-align: left;
  list-style-type: none;
}

.debug-border {
  /* border: solid 1px rgb(0, 0, 0); */
}

#game-content {
  width: 80%;
  max-width: 800px
}

canvas {
  width: 100%;
}

#react-game-buttons {
  display: 'inline';
  width: 100%;
}

.game-button {
  background-color: black;
  color: #fff;
  width: 30%;
  padding: 5%;
  margin: 2.5%;
  margin-bottom: 10%
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}